/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable react/jsx-filename-extension */

/* eslint-disable @typescript-eslint/no-floating-promises */
import { ILangState } from '@contextTypes';
import { IPost } from '@standardTypes';
import { graphql, useStaticQuery } from 'gatsby';

import { LangCtx, useContextState } from '@components/contexted';
import { Layout, PageNotFound } from '@components/layout';

interface IPostsType {
  WP: {
    posts: {
      nodes: IPost[];
    };
  };
}
const NotFoundPage = () => {
  const { language } = useContextState<ILangState>(LangCtx, 'language');
  const { WP } = useStaticQuery<IPostsType>(graphql`
    {
      WP {
        posts(first: 1000) {
          nodes {
            id
            slug
            title
            date
            databaseId
            content
            excerpt
            language {
              code
            }
            allLanguages {
              code
              slug
            }
            featuredImage {
              node {
                altText
                srcSet
                sourceUrl
                sizes
                imageFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const posts = WP.posts.nodes;

  const currentLanguagePosts = posts
    .filter((post) => post.language.code.toLowerCase() === language)
    .slice(0, 3);

  const seo = {
    title: '404 - Testspring'
  };

  return (
    <>
      <Layout seo={seo}>
        <PageNotFound posts={currentLanguagePosts} />
      </Layout>
    </>
  );
};

export default NotFoundPage;
